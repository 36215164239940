@if ({
  currentLanguage: languageService.currentLanguage$ | async,
  isMobile: domService.isHandset$ | async,
  breadcrumbs: mainNavInterfaceService.breadcrumbs | async
}; as AsyncItems) {
  <mat-card appearance="outlined">
    <ul [class.breadcrumb-list-mobile]="AsyncItems.isMobile"
        [dir]="AsyncItems.currentLanguage.direction"
        class="breadcrumb-list"
    >
      @if (!AsyncItems.isMobile) {
        @for (breadcrumb of AsyncItems.breadcrumbs; track breadcrumb) {
          <li [dir]="AsyncItems.currentLanguage.direction">
            <span (click)="handleBreadcrumbClick(breadcrumb)">{{ breadcrumb.label | translate }}</span>
          </li>
        }
      }

      @if (AsyncItems.currentLanguage.direction == 'ltr' && AsyncItems.isMobile) {
        @for (breadcrumb of AsyncItems.breadcrumbs.slice(-2); track breadcrumb) {
          <li>
            <span (click)="handleBreadcrumbClick(breadcrumb)">{{ breadcrumb.label | translate }}</span>
          </li>
        }
      }

      @if (AsyncItems.currentLanguage.direction == 'rtl' && AsyncItems.isMobile) {
        @for (breadcrumb of AsyncItems.breadcrumbs.slice(2); track breadcrumb) {
          <li>
            <span (click)="handleBreadcrumbClick(breadcrumb)">{{ breadcrumb.label | translate }}</span>
          </li>
        }
      }
    </ul>
  </mat-card>
}
