import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FirebaseService } from '@services/firebase.service';
import { MainNavInterfaceService } from '@services/main-nav-interface.service';
import { LanguageService } from '@services/language.service';
import { ThemeService } from '@services/theme.service';
import { DomService } from '@services/dom.service';
import { AuthService } from '@services/auth.service';
import { ValidRoutes } from '@interfaces/valid-routes';
import { BackendFunctionResponse, Language, MainMenuItem } from '@interfaces/entities';
import { HttpsCallableResult } from '@angular/fire/functions';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent implements OnInit, OnDestroy {
  validRoutes = ValidRoutes; // to make it accessible in the template.
  searchTerm = '';
  allSubscriptions = new Subscription(); // So that we can unsubscribe to all observable at once.
  menuItems: MainMenuItem[];
  availableLanguages: Language[] = [];
  upcomingLanguages: Language[] = [];
  userIsLoggedIn = false;
  searchIconHidden = false;
  constructor(
    private ngZone: NgZone,
    public authService: AuthService,
    public mainNavInterfaceService: MainNavInterfaceService,
    public domService: DomService,
    private firebaseService: FirebaseService,
    private router: Router,
    public languageService: LanguageService,
    public themeService: ThemeService,
    public dialog: MatDialog,
  ) {
  }
  ngOnInit(): void {
    // Initialize main menu items:
    this.menuItems = [
      { materialIcon: 'home', i18nText: 'common.home', routerLink: '/', availableFor: 'public' },
      { materialIcon: 'record_voice_over', i18nText: 'artist.plural', routerLink: '/artists/', availableFor: 'public' },
      { materialIcon: 'backup', i18nText: 'common.upload', routerLink: '/upload-nasheed/', availableFor: 'public' },
      { materialIcon: 'admin_panel_settings', i18nText: 'moderate.heading', routerLink: '/moderate', availableFor: 'moderator' },
      { materialIcon: 'privacy_tip', i18nText: 'privacy.title', routerLink: '/privacy-policy', availableFor: 'public' },
    ];

    this.availableLanguages = this.languageService.getAvailableLanguages();
    this.upcomingLanguages = this.languageService.getUpcomingLanguages();
    this.authService.userIsLoggedIn.subscribe(value => this.userIsLoggedIn = value);
  }
  toggleDarkTheme(enable: boolean): void {
    if (enable)
      this.themeService.enableDarkTheme();
    else
      this.themeService.enableLightTheme();
  }
  uploadNasheed(): void {
    if (this.userIsLoggedIn) {
      this.router.navigateByUrl(this.validRoutes.UploadNasheed).catch(console.error);
      return;
    }
    void this.authService.openAuthDialog({
      loginScreenHeading: 'signInBefore.uploadingNasheed',
    }).then(dialogResponse => {
      if (dialogResponse) {
        this.router.navigateByUrl(this.validRoutes.UploadNasheed).catch(console.error);
      }
    });
  }
  deleteBots(): void {
    const confirmation = prompt("Are you sure you want to continue? (type 'yes' to confirm)");
    if (confirmation === 'yes') {
      const deletionType = prompt("Dou you want to remove ALL inactive users or only GOOGLE users? (type 'all' or 'google' to confirm)");
      if (deletionType !== 'all' && deletionType !== 'google') {
        alert('Operation cancelled, Invalid selection.');
      } else {
        this.firebaseService.callCloudFunction('deleteInactiveUsers', deletionType).subscribe(value => {
          console.info(value);
        });
      }
    } else {
      alert('Operation cancelled');
    }
  }
  refreshAdminTrends(): void {
    const confirmation = prompt('Enter Nasheed Id or leave empty to refresh all trending nasheeds');
    this.firebaseService.callCloudFunction('forceUpdateTrendsAdmin', confirmation).subscribe((value: HttpsCallableResult<BackendFunctionResponse>) => {
      if (value.data.success) {
        alert('Operation completed successfully');
      } else {
        alert('Operation failed');
        console.warn(value.data);
      }
    });
  }
  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe();
  }
}
