import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {  AuthPipe, redirectUnauthorizedTo, AuthGuard } from '@services/firebase.service';
import { CustomPreloadStrategyService } from '@services/custom-preload-strategy.service';
import { MainNavComponent } from './main-nav/main-nav.component';
import { ValidRoutes } from '@interfaces/valid-routes';
import { HomeModule } from './home/home.module';
import { UploadModule } from './routes/upload/upload.module';
import { ArtistsModule } from './routes/artists/artists.module';
import { PagesModule } from './pages/pages.module';
import { ModerateModule } from './routes/moderate/moderate.module';
import { PrivacyPolicyModule } from './routes/privacy-policy/privacy-policy.module';
import { SupportModule } from './routes/support/support.module';
import { DeleteAccountModule } from './routes/delete-account/delete-account.module';
import { LoginRouteComponent } from './auth/login-route/login-route.component';

const redirectUnauthorizedToLogin = (): AuthPipe => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '',
    component: MainNavComponent,
    children: [
      {
        data: { preload: true },
        path: '',
        loadChildren: ():Promise<Routes | typeof HomeModule> => import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: ValidRoutes.Login,
        component: LoginRouteComponent,
      },
      {
        path: ValidRoutes.UploadNasheed,
        loadChildren: (): Promise<Routes | typeof UploadModule> => import('./routes/upload/upload.module').then((m) => m.UploadModule),
        canActivate: [AuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
      },
      {
        data: { preload: true },
        path: ValidRoutes.Artists,
        loadChildren: (): Promise<Routes | typeof ArtistsModule> => import('./routes/artists/artists.module').then((m) => m.ArtistsModule),
      },
      {
        data: { preload: true },
        path: ValidRoutes.Details,
        loadChildren: (): Promise<Routes | typeof PagesModule> => import('./pages/pages.module').then((m) => m.PagesModule),
      },
      {
        path: ValidRoutes.Moderate,
        loadChildren: (): Promise<Routes | typeof ModerateModule> => import('./routes/moderate/moderate.module').then((m) => m.ModerateModule),
        canActivate: [AuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
      },
      {
        path: ValidRoutes.PrivacyPolicy,
        loadChildren: (): Promise<Routes | typeof PrivacyPolicyModule> => import('./routes/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
      },
      {
        path: ValidRoutes.ContactAndSupport,
        loadChildren: (): Promise<Routes | typeof SupportModule> => import('./routes/support/support.module').then((m) => m.SupportModule),
      },
      {
        path: ValidRoutes.DeleteAccount,
        loadChildren: (): Promise<Routes | typeof DeleteAccountModule> => import('./routes/delete-account/delete-account.module').then((m) => m.DeleteAccountModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    preloadingStrategy: CustomPreloadStrategyService,
  })],
  exports: [RouterModule],
})

export class AppRoutingModule {
}
