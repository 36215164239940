import { Component, OnInit } from '@angular/core';
import { MainNavInterfaceService } from '@services/main-nav-interface.service';
import { LanguageService } from '@services/language.service';
import { DomService } from '@services/dom.service';
import { Breadcrumb } from '@interfaces/entities';
import { Router } from '@angular/router';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {

  constructor(public mainNavInterfaceService: MainNavInterfaceService,
    private router: Router,
    public languageService: LanguageService,
    public domService: DomService,
  ) { }
  ngOnInit(): void {
  }
  handleBreadcrumbClick(breadCrumb: Breadcrumb): void {
    this.router.navigateByUrl(breadCrumb.url).catch(console.error);
  }
}
