import { Component, OnInit } from '@angular/core';
import { DomService } from '@services/dom.service';
import { AuthService } from '@services/auth.service';
import { ValidRoutes } from '@interfaces/valid-routes';
import { Router } from '@angular/router';

@Component({
  selector: 'ns-login-route',
  template: '',
  styleUrls: [],
  standalone: true,
})
export class LoginRouteComponent implements OnInit {

  constructor(private authService: AuthService, private domService: DomService, private router: Router) {
  }
  ngOnInit(): void {
    const lastRoute = this.domService.localStorage.getItem('previousUrl') as ValidRoutes;

    void this.authService.openAuthDialog({}).then(dialogResponse => {
      if (!dialogResponse) {
        this.router.navigateByUrl(ValidRoutes.Root).catch(console.error);
      } else {
        setTimeout(() => {
          this.router.navigate([lastRoute]).catch(console.error);
        }, 500);
      }
    });
  }
}
