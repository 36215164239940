import { Component } from '@angular/core';
import {
  Router,
  RoutesRecognized,
} from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { DomService } from '@services/dom.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})


export class AppComponent {
  constructor(private router: Router, private domService: DomService) {
    router.events
      .pipe(filter((evt: RoutesRecognized) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.domService.localStorage.setItem('previousUrl', events[0].urlAfterRedirects);
      });
  }
}
