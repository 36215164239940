@if ({
  currentLanguage: languageService.currentLanguage$ | async,
  isMobile: domService.isHandset$ | async,
  heading: this.mainNavInterfaceService.currentHeading$ | async,
  userIsLoggedIn: authService.userIsLoggedIn | async,
  currentUser: authService.currentUser | async,
  darkThemeEnabled: themeService.darkThemeEnabled | async
}; as AsyncItems) {
  <audio-player [class.full-width]="AsyncItems.isMobile"
                [class.player-container]="!AsyncItems.isMobile"
                [dir]="AsyncItems.currentLanguage.direction"
                class="player">
  </audio-player>

  <mat-sidenav-container [hasBackdrop]="AsyncItems.isMobile"
                         [ngClass]="AsyncItems.darkThemeEnabled ?  'dark-theme': 'light-theme'"
                         autosize>
    <mat-sidenav #drawer
                 [attr.role]="AsyncItems.isMobile ? 'dialog' : 'navigation'"
                 [dir]="AsyncItems.currentLanguage.direction"
                 [mode]="AsyncItems.isMobile ? 'over' : 'side'"
                 [opened]="!AsyncItems.isMobile"
                 [position]="AsyncItems.currentLanguage.direction == 'ltr' ? 'start' : 'end'"
                 class="sidenav"
                 fixedInViewport>
      <mat-toolbar [dir]="AsyncItems.currentLanguage.direction" style="background: transparent">
        @if (!AsyncItems.isMobile) {
          <mat-toolbar-row [routerLink]="['/']" style="cursor: pointer;">
            <img [ngSrc]="domService.assetsDirectory + 'nasheed_icon.svg'"
                 alt="Nasheed Station"
                 width="35"
                 height="35"
            />
            <span [dir]="AsyncItems.currentLanguage.direction"
                  class="stretch-badge logo-text-in-sidenav"
            >{{ 'ns.titleWithoutN' | translate }}</span>
          </mat-toolbar-row>
        }
        @if (AsyncItems.isMobile) {
          <mat-toolbar-row>
            <span style="font-size: 24px;">{{ AsyncItems.heading | translate }}</span>
          </mat-toolbar-row>
        }
      </mat-toolbar>

      <mat-nav-list [dir]="AsyncItems.currentLanguage.direction">
        <div>
          @for (menu_item of menuItems; track menu_item) {
            @if (menu_item.availableFor === 'public' || (menu_item.availableFor === 'moderator' && AsyncItems.currentUser?.isModerator) || (menu_item.availableFor === 'loggedIn' && AsyncItems.userIsLoggedIn)) {
              <a (click)="AsyncItems.isMobile ? drawer.toggle(): null"
                 [routerLink]="[menu_item.routerLink]"
                 mat-list-item
              >
                <mat-icon mat-list-icon>{{ menu_item.materialIcon }}</mat-icon>
                {{ menu_item.i18nText | translate }}
              </a>
            }
          }

          <div class="social-platforms-container">
            <div class="availability-message">{{ 'ns.androidIOSAvailabilityMessage' | translate }}</div>
            <div class="platforms-icons-row">
              <div></div>
              <a href="https://apps.apple.com/us/app/nasheed-station/id1663267109" target="_blank">
                <img [ngSrc]="domService.assetsDirectory + 'download_from_app_store.svg'"
                     width="102"
                     height="30"
                     alt="App Store Icon"/>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.nasheedstation.app" target="_blank">
                <img [ngSrc]="domService.assetsDirectory + 'download_from_play_store.svg'"
                     width="102"
                     height="30"
                     alt="Play Store Icon"/>
              </a>
              <div></div>
            </div>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content [dir]="AsyncItems.currentLanguage.direction">
      <mat-toolbar color="{{AsyncItems.isMobile ? 'primary' : ''}}">
        <mat-toolbar-row [ngClass]="AsyncItems.isMobile ? 'p-0' : null">
          @if (AsyncItems.isMobile) {
            <button
              (click)="drawer.toggle()"
              aria-label="Open menu on the left hand side"
              mat-icon-button
              type="button">
              <mat-icon aria-label="Open Menu">menu</mat-icon>
            </button>
          }

          <!-- Current page heading in toolbar -->
          <!--@if(!AsyncItems.isMobile) {
            <span class="page-heading-in-toolbar">
              @if(AsyncItems.heading === 'common.welcome') {
                <span>{{'common.welcome' | translate}} {{AsyncItems.currentUser?.displayName}}</span>
              }
              @if (AsyncItems.heading !== 'common.welcome') {
                <span> {{AsyncItems.heading | translate}}</span>
              }
            </span>
          }-->

          @if (!searchIconHidden && AsyncItems.isMobile) {
            <div class="flex-center">
              <!--<mat-icon>{{logo.material_icon}}</mat-icon>-->
              <img [ngSrc]="domService.assetsDirectory + 'nasheed_icon.svg'"
                   alt="Nasheed Station"
                   width="30"
                   height="30"
              />
              <span class="logo-text-in-sidenav">{{ 'ns.title'| translate }}</span>
            </div>
          }

          <span class="spacer" id="spacer"></span>

          <ns-global-search (searchIconHiddenEmitter)="searchIconHidden = $event"></ns-global-search>

          @if (!AsyncItems.isMobile) {
            <div>
              <button (click)="uploadNasheed()"
                      class="toolbar-upload-nasheed-icon"
                      mat-icon-button
                      matTooltip="{{'nasheed.upload'|translate}}"
                      name="btn-upload">
                <mat-icon>cloud_upload</mat-icon>
              </button>
            </div>
          }

          @if (!AsyncItems.userIsLoggedIn) {
            <button id="menu-more-vert"
                    mat-icon-button>
              <mat-icon [matMenuTriggerFor]="profileMenu">more_vert</mat-icon>
            </button>
          }

          @if (!AsyncItems.isMobile) {
            <div>
              @if (!AsyncItems.userIsLoggedIn) {
                <button (click)="authService.openAuthDialog({})"
                        [class]="AsyncItems.darkThemeEnabled ? 'dark-theme' : 'light-theme'"
                        id="btnSignIn"
                        mat-raised-button>
                  @if (authService.loadingAuthDialog) {
                    <mat-icon>
                      <mat-spinner diameter="20"></mat-spinner>
                    </mat-icon>
                  }
                  {{ 'auth.signIn'|translate }}
                </button>
              }
            </div>
          }

          <div class="flex-center me-5">
            @if (AsyncItems.userIsLoggedIn) {
              <img [ngSrc]="AsyncItems?.currentUser?.photoURL || domService.assetsDirectory + 'profile_image.jpeg'"
                   [height]="40"
                   [matMenuTriggerFor]="profileMenu"
                   [width]="40"
                   alt="Profile Menu"
                   class="profile-photo-icon"
                   id="profileMenuIcon"
              >
            }
          </div>

        </mat-toolbar-row>
      </mat-toolbar>
      <mat-divider></mat-divider>

      <!--<ng-content></ng-content>-->

      <div [class]="AsyncItems.darkThemeEnabled ? 'dark-theme' : 'light-theme'">
        <router-outlet></router-outlet>

        <div class="footer">
          <div [style.max-width]="menuItems.length * 80 + 'px'"
               [style.width]="AsyncItems.isMobile ? '90%' : '100%'"
               style="margin: auto"
          >
            <hr/>
          </div>
          <div [ngClass]="AsyncItems.darkThemeEnabled? 'dark-items': 'light-items'"
               class="footer-items">
            @for (menuItem of menuItems; track menuItem; let i = $index) {
              @if (menuItem.availableFor === 'public' || (menuItem.availableFor === 'moderator' && AsyncItems.currentUser?.isModerator) || (menuItem.availableFor === 'loggedIn' && AsyncItems.userIsLoggedIn)) {
                <a [routerLink]="menuItem.routerLink">{{ menuItem.i18nText | translate }}</a>
                @if (i !== (menuItems.length - 1)) {
                  <span> | </span>
                }
              }
            }
          </div>
        </div>

        <div style="height: 120px"></div>
        <div class="breadcrumb-container">
          <breadcrumbs></breadcrumbs>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <!-- Profile Menu -->
  <mat-menu #profileMenu="matMenu" [class]="AsyncItems.darkThemeEnabled ? 'dark-theme' : 'light-theme'"
            class="my-class">
    @if (AsyncItems.userIsLoggedIn) {
      <div (click)="$event.stopPropagation();"
           class="profile-card">
        <button id="profile-icon"
                mat-button
                style="padding-left: 0; pointer-events: none"
        >
          <img [ngSrc]="AsyncItems?.currentUser?.photoURL || domService.assetsDirectory + 'profile_image.jpeg'"
               height="50"
               width="50"
               alt="Profile Menu"
               class="profile-photo-icon"
          ></button>
        <div>
          <div [ngStyle]="{color: AsyncItems.darkThemeEnabled? '#EEEEEE' : 'black'}">
          <span id="currentlyLoggedUserFullName">
            <strong>{{ AsyncItems?.currentUser?.displayName }}</strong>
          </span>
          </div>
          <div [ngStyle]="{color: AsyncItems.darkThemeEnabled? '#EEEEEE' : 'black'}">
            <span>{{ AsyncItems?.currentUser?.email }}</span>
          </div>
        </div>
      </div>
    }

    @if (AsyncItems.userIsLoggedIn) {
      <mat-divider></mat-divider>
    }

    <div (click)="$event.stopPropagation();">
      @if (AsyncItems.isMobile && !AsyncItems.userIsLoggedIn) {
        <button (click)="authService.openAuthDialog({})"
                mat-menu-item>
          @if (authService.loadingAuthDialog) {
            <mat-icon>
              <mat-spinner diameter="20"></mat-spinner>
            </mat-icon>
          } @else {
            <mat-icon>login</mat-icon>
          }
          {{ 'auth.signIn'|translate }}
        </button>
      }

      @if (AsyncItems.isMobile) {
        <button mat-menu-item name="btn-upload"
                routerLink="/upload-nasheed">
          <mat-icon>cloud_upload</mat-icon>
          {{ 'nasheed.upload'|translate }}
        </button>
      }

      <button [mat-menu-trigger-for]="themeCard" mat-menu-item>
        <mat-icon style="font-size:20px">brightness_medium</mat-icon>
        {{ 'theming.darkTitle'|translate }}: {{ (AsyncItems.darkThemeEnabled ? 'common.on' : 'common.off') |translate }}
      </button>
      <button [mat-menu-trigger-for]="language" mat-menu-item>
        <mat-icon style="font-size:20px">translate</mat-icon>
        {{ 'common.language'|translate }}
      </button>
      @if (AsyncItems.userIsLoggedIn && AsyncItems?.currentUser?.email === 'admin@nasheedstation.com') {
        <button [mat-menu-trigger-for]="criticalSectionOfAdminCard" mat-menu-item>
          <mat-icon style="font-size:20px">label_important</mat-icon>
          Critical Section
        </button>
      }
      @if (AsyncItems.userIsLoggedIn) {
        <button (click)="authService.signOut()" id="btnSignOut"
                mat-menu-item>
          <mat-icon style="font-size:20px">power_settings_new</mat-icon>
          {{ 'auth.signOut'|translate }}
        </button>
      }
    </div>
  </mat-menu>

  <!-- Languages list-->
  <mat-menu #language="matMenu">
    @for (language of availableLanguages; track language) {
      <span>
        <button (click)="languageService.setLanguage(language?.iso)" mat-menu-item>{{ language.name }}</button>
      </span>
    }
    @if (AsyncItems?.currentUser?.isModerator) {
      @for (language of upcomingLanguages; track language) {
        <span>
          <button (click)="languageService.setLanguage(language?.iso)" mat-menu-item
                  style="opacity: 0.5">{{ language.name }}</button>
        </span>
      }
    }

  </mat-menu>

  <!-- Theme Card-->
  <mat-menu #themeCard="matMenu">
    <button mat-menu-item>
      <div>
        <mat-slide-toggle (change)="toggleDarkTheme($event.checked)" [checked]="AsyncItems.darkThemeEnabled"
                          labelPosition="before">{{ 'theming.darkTitle'|translate }}&nbsp;&nbsp;&nbsp;
        </mat-slide-toggle>
      </div>
    </button>
  </mat-menu>

  <mat-menu #criticalSectionOfAdminCard="matMenu">
    @if (AsyncItems.userIsLoggedIn && AsyncItems?.currentUser?.email === 'admin@nasheedstation.com') {
      <button (click)="deleteBots()" mat-menu-item>
        <mat-icon mat-list-icon>delete_forever</mat-icon>
        Delete Bots
      </button>

      <button (click)="refreshAdminTrends()" mat-menu-item>
        <mat-icon mat-list-icon>sync</mat-icon>
        Refresh Trending (admin)
      </button>
    }
  </mat-menu>
}
