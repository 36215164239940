import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { enableDebugTools } from '@angular/platform-browser';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(console.warn);
} else {
  platformBrowserDynamic().bootstrapModule(AppModule).then((module) => {
    enableDebugTools(module.injector.get(ApplicationRef).components[0]);
  }).catch(console.warn);
}
