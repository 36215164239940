/* Modules */
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FirebaseModule } from './firebase.module';
import { AppRoutingModule } from './app-routing.module';
import { I18nModule } from './i18n.module';

/* Components and Objects */
import { AppComponent } from './app.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { SecondsToMinutesPipe } from '@utils/pipes/seconds-to-minutes.pipe';
import { environment } from '../environments/environment';

import { ServiceWorkerModule } from '@angular/service-worker';
import { GlobalSearchComponent } from './main-nav/global-search/global-search.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NsListItemModule } from '@angular-shared/components/list-item/ns-list-item.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NsBreadcrumbsModule } from '@angular-shared/components/breadcrumbs/ns-breadcrumbs.module';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgOptimizedImage } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    SecondsToMinutesPipe,
    AudioPlayerComponent,
    GlobalSearchComponent,
  ],
  imports: [
    I18nModule.translateModule(),
    I18nModule.forRoot(),
    FirebaseModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    // Angular Material Imports used only in root of the project.
    MatDialogModule,
    DragDropModule,
    NsListItemModule,
    MatSidenavModule,
    MatToolbarModule,
    MatBadgeModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonModule,
    MatSlideToggleModule,
    NsBreadcrumbsModule,
    MatCardModule,
    MatSliderModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NgOptimizedImage,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
