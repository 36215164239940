<audio #audioPlayer [src]="tracks[currentIndex]?.mp3" autoplay="autoplay"></audio>
@if ({
  currentLanguage: languageService.currentLanguage$ | async
}; as AsyncItems) {
  <div class="adjust-bw-color" [hidden]="!(this.audioPlayerService.getPlaylist() | async)?.length" cdkDrag>
    @if (displayTitle) {
      <mat-card appearance="outlined" cdkDragHandle
                class="mat-elevation-z1 ngx-audio-player top-bar-style">
        <div style="text-align: center; width: 100%">
          @if ({
            trackTitle: tracks[currentIndex]?.title[AsyncItems.currentLanguage.iso] || tracks[currentIndex]?.title['en'],
            artistTitle: tracks[currentIndex]?.artist[AsyncItems.currentLanguage.iso] || tracks[currentIndex]?.artist['en']
          }; as TrackInfo) {
            <div style="margin: 1px 2px; padding: 1em; text-align: center; position: relative; bottom: 20px;">
              @if (!isPlaying) {
                <span>{{ TrackInfo.trackTitle }}{{ displayArtist && tracks[currentIndex]?.artist ? ' | ' + TrackInfo.artistTitle : '' }}</span>
              }

              @if (isPlaying) {
                <marquee [direction]="AsyncItems.currentLanguage.direction == 'ltr' ? 'left' : 'right'"
                         behavior="scroll">
                  {{ TrackInfo.trackTitle }}{{ displayArtist && TrackInfo.artistTitle ? ' | ' + TrackInfo.artistTitle : '' }}
                </marquee>
              }
            </div>
          }
          <div class="clear"></div>
        </div>
      </mat-card>
    }

    <mat-card appearance="outlined"
              class="ngx-d-flex ngx-justify-content-center ngx-audio-player z-depth-1 mat-elevation-z2"
              style="margin: 0px;">

      @if (tracks.length > 1) {
        <button (click)='previousSong();' [disabled]="loaderDisplay" class="ngx-p-1" mat-button>
          <mat-icon aria-hidden="true">
            <!-- Skip previous icon (skip_previous) -->
            <svg height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 6h2v12H6zm3.5 6l8.5 6V6z"/>
              <path d="M0 0h32v32H0z" fill="none"/>
            </svg>
          </mat-icon>
        </button>
      }

      <button (click)='playBtnHandler();' [disabled]="loaderDisplay" class="ngx-p-1 play-pause" mat-button>

        @if (loaderDisplay) {
          <svg height="34px" preserveAspectRatio="xMidYMid"
               style="margin: auto; display: block; shape-rendering: auto;" viewBox="0 0 100 100" width="34px"
               xmlns="http://www.w3.org/2000/svg">
            <g transform="rotate(0 50 50)">
              <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
                <animate attributeName="opacity" begin="-0.9166666666666666s" dur="1s" keyTimes="0;1"
                         repeatCount="indefinite" values="1;0"/>
              </rect>
            </g>
            <g transform="rotate(30 50 50)">
              <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
                <animate attributeName="opacity" begin="-0.8333333333333334s" dur="1s" keyTimes="0;1"
                         repeatCount="indefinite" values="1;0"/>
              </rect>
            </g>
            <g transform="rotate(60 50 50)">
              <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
                <animate attributeName="opacity" begin="-0.75s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                         values="1;0"/>
              </rect>
            </g>
            <g transform="rotate(90 50 50)">
              <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
                <animate attributeName="opacity" begin="-0.6666666666666666s" dur="1s" keyTimes="0;1"
                         repeatCount="indefinite" values="1;0"/>
              </rect>
            </g>
            <g transform="rotate(120 50 50)">
              <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
                <animate attributeName="opacity" begin="-0.5833333333333334s" dur="1s" keyTimes="0;1"
                         repeatCount="indefinite" values="1;0"/>
              </rect>
            </g>
            <g transform="rotate(150 50 50)">
              <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
                <animate attributeName="opacity" begin="-0.5s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                         values="1;0"/>
              </rect>
            </g>
            <g transform="rotate(180 50 50)">
              <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
                <animate attributeName="opacity" begin="-0.4166666666666667s" dur="1s" keyTimes="0;1"
                         repeatCount="indefinite" values="1;0"/>
              </rect>
            </g>
            <g transform="rotate(210 50 50)">
              <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
                <animate attributeName="opacity" begin="-0.3333333333333333s" dur="1s" keyTimes="0;1"
                         repeatCount="indefinite" values="1;0"/>
              </rect>
            </g>
            <g transform="rotate(240 50 50)">
              <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
                <animate attributeName="opacity" begin="-0.25s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                         values="1;0"/>
              </rect>
            </g>
            <g transform="rotate(270 50 50)">
              <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
                <animate attributeName="opacity" begin="-0.16666666666666666s" dur="1s" keyTimes="0;1"
                         repeatCount="indefinite" values="1;0"/>
              </rect>
            </g>
            <g transform="rotate(300 50 50)">
              <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
                <animate attributeName="opacity" begin="-0.08333333333333333s" dur="1s" keyTimes="0;1"
                         repeatCount="indefinite" values="1;0"/>
              </rect>
            </g>
            <g transform="rotate(330 50 50)">
              <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
                <animate attributeName="opacity" begin="0s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                         values="1;0"/>
              </rect>
            </g>
          </svg>
        }

        @if (!loaderDisplay && !isPlaying) {
          <mat-icon aria-hidden="true" class="play-track">
            <!-- Play icon (play_arrow) -->
            <svg height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 5v14l11-7z"/>
              <path d="M0 0h24v24H0z" fill="none"/>
            </svg>
          </mat-icon>
        }

        @if (!loaderDisplay && isPlaying) {
          <mat-icon aria-hidden="true" class="pause-track">
            <!-- Pause icon (pause) -->
            <svg height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
              <path d="M0 0h24v24H0z" fill="none"/>
            </svg>
          </mat-icon>
        }
      </button>

      @if (tracks.length > 1) {
        <button (click)='nextSong();' [disabled]="loaderDisplay" class="ngx-p-1 skip-next"
                mat-button>
          <mat-icon aria-hidden="true" class="next-track">
            <!-- Skip next icon (skip_next) -->
            <svg height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z"/>
              <path d="M0 0h24v24H0z" fill="none"/>
            </svg>
          </mat-icon>
        </button>
      }

      <div class="ngx-col">
        <div class="ngx-d-flex ngx-flex-fill ngx-justify-content-center">
          <div class="ngx-d-none ngx-d-sm-block ngx-py-3 ngx-px-1" style="font-size: 12px">
            @if (duration !== 0.01) {
              <span>{{ currentTime | secondsToMinutes }}</span>
            }
          </div>
          <mat-slider #ngSlider [disabled]="disablePositionSlider"
                      [min]="startOffset" class="ngx-d-none ngx-d-sm-block ngx-flex-fill ngx-p-1"
                      max="{{duration-endOffset}}" style="width: 100%"><input #ngSliderThumb="matSliderThumb"
                                                                              (change)="currTimePosChanged({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})"
                                                                              matSliderThumb
                                                                              value="{{currentTime}}"/></mat-slider>

          <div class="ngx-py-3 ngx-px-1" style="font-size: 12px; text-align: right">
            @if (duration !== 0.01) {
              <span>-{{ duration - currentTime | secondsToMinutes }}</span>
            }
          </div>
        </div>
      </div>
      @if (displayRepeatControls) {
        <button (click)='toggleRepeat();' class="ngx-p-1 volume justify-content-center"
                mat-button>
          @if (repeat === 'none') {
            <mat-icon aria-hidden="true" class="volume-mute">
              <!-- Repeat None -->
              <svg height="24" viewBox="0 0 22 22" width="24" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <g>
                    <path d="m0,0l24,0l0,24l-24,0l0,-24z" fill="none"/>
                    <path
                      d="m8,20l0,1.932a0.5,0.5 0 0 1 -0.82,0.385l-4.12,-3.433a0.5,0.5 0 0 1 0.322,-0.884l14.618,0a2,2 0 0 0 2,-2l0,-8l2,0l0,8a4,4 0 0 1 -4,4l-10,0zm8,-16l0,-1.932a0.5,0.5 0 0 1 0.82,-0.385l4.12,3.433a0.5,0.5 0 0 1 -0.321,0.884l-14.619,0a2,2 0 0 0 -2,2l0,8l-2,0l0,-8a4,4 0 0 1 4,-4l10,0z"/>
                    <path d="M 3 0 L 22.4164 22.2706 L 21 24 L 1.5836 1.8128 L 3 0"></path>
                  </g>
                  <!-- <line xmlns="http://www.w3.org/2000/svg" id="svg_4" y2="22.27056" x2="22.41638" y1="1.81276" x1="1.58362" stroke-width="2" fill="none"/> -->
                </g>
              </svg>
            </mat-icon>
          } @else if (repeat === 'all' && tracks.length > 1) {
            <mat-icon aria-hidden="true" class="volume-mute">
              <!-- Repeat All -->
              <svg height="24" viewBox="0 0 22 22" width="24" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path
                    d="M8 20v1.932a.5.5 0 0 1-.82.385l-4.12-3.433A.5.5 0 0 1 3.382 18H18a2 2 0 0 0 2-2V8h2v8a4 4 0 0 1-4 4H8zm8-16V2.068a.5.5 0 0 1 .82-.385l4.12 3.433a.5.5 0 0 1-.321.884H6a2 2 0 0 0-2 2v8H2V8a4 4 0 0 1 4-4h10z"/>
                </g>
              </svg>
            </mat-icon>
          } @else if (repeat === 'one' || (repeat === 'all' && tracks.length == 1)) {
            <mat-icon aria-hidden="true"
                      class="volume-up">
              <!-- Repeat ONE -->
              <svg height="24" viewBox="0 0 22 22" width="24" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path
                    d="M8 20v1.932a.5.5 0 0 1-.82.385l-4.12-3.433A.5.5 0 0 1 3.382 18H18a2 2 0 0 0 2-2V8h2v8a4 4 0 0 1-4 4H8zm8-16V2.068a.5.5 0 0 1 .82-.385l4.12 3.433a.5.5 0 0 1-.321.884H6a2 2 0 0 0-2 2v8H2V8a4 4 0 0 1 4-4h10zm-5 4h2v8h-2v-6H9V9l2-1z"/>
                </g>
              </svg>
            </mat-icon>
          }
        </button>
      }

      @if (displayVolumeControls) {
        <button (click)='toggleVolume();' class="ngx-p-1 volume" mat-button>
          @if (volume === 0) {
            <mat-icon aria-hidden="true" class="volume-mute icon-font-fix">
              volume_off
            </mat-icon>
          }
          @if (volume > 0) {
            <mat-icon aria-hidden="true" class="volume-up icon-font-fix">
              volume_up
            </mat-icon>
          }
        </button>
      }

      @if (displayPlaylist && tracks.length > 1) {
        <button (click)='expanded = !expanded' class="ngx-p-1 volume"
                mat-button>
          @if (expanded) {
            <mat-icon aria-hidden="true" class="icon-font-fix">
              keyboard_arrow_up
            </mat-icon>
          } @else {
            <mat-icon aria-hidden="true" class="icon-font-fix">
              keyboard_arrow_down
            </mat-icon>
          }
        </button>
      }
    </mat-card>


    @if (displayPlaylist && tracks.length > 1) {
      <mat-accordion class="ngx-audio-player">
        <mat-expansion-panel [expanded]="expanded" style="margin-top: 3px;">
          <!--      <mat-expansion-panel-header>-->
          <!--        {{tableHeader}}-->
          <!--      </mat-expansion-panel-header>-->
          <table [dataSource]="dataSource" class="mat-elevation-z6" mat-table>
            <ng-container matColumnDef="title">
              <th *matHeaderCellDef mat-header-cell>{{ titleHeader }}</th>
              <td (click)="selectTrack(element.index)" *matCellDef="let element" mat-cell>
                {{ element.title[AsyncItems.currentLanguage.iso] }}
              </td>
            </ng-container>
            <ng-container matColumnDef="artist">
              <th *matHeaderCellDef mat-header-cell>{{ artistHeader }}</th>
              <td (click)="selectTrack(element.index)" *matCellDef="let element" mat-cell>
                {{ element?.artist[AsyncItems.currentLanguage.iso] ? element?.artist[AsyncItems.currentLanguage.iso] : '' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="duration">
              <th *matHeaderCellDef mat-header-cell>{{ durationHeader }}</th>
              <td (click)="selectTrack(element.index)" *matCellDef="let element" mat-cell>
                {{ element?.duration ? (element?.duration | secondsToMinutes) : '' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let element" mat-cell>
                @if (tracks[currentIndex]?.title[AsyncItems.currentLanguage.iso] === element.title[AsyncItems.currentLanguage.iso]) {
                  <div>
                    <!--@if(isPlaying) {
                      <mat-icon aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                      </mat-icon>
                    }-->
                    @if (isPlaying) {
                      <mat-icon aria-hidden="true" class="currently-playing">
                        <!-- Play icon (play_arrow) -->
                        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0 0h24v24H0z" fill="none"/>
                          <path
                            d="M12 3v9.28c-.47-.17-.97-.28-1.5-.28C8.01 12 6 14.01 6 16.5S8.01 21 10.5 21c2.31 0 4.2-1.75 4.45-4H15V6h4V3h-7z"/>
                        </svg>
                      </mat-icon>
                    }
                  </div>
                }
              </td>
            </ng-container>
            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" class="mat-select-content" mat-row></tr>
          </table>
          <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
        </mat-expansion-panel>
      </mat-accordion>
    }
  </div>
}
