import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomService } from '@services/dom.service';
import { FirebaseService, SearchType } from '@services/firebase.service';
import { Router } from '@angular/router';
import { LanguageService } from '@services/language.service';
import { ThemeService } from '@services/theme.service';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SearchResult } from '@interfaces/entities';
import { Observable, Subscription } from 'rxjs';
import { Utils } from '@utils/utils';

@Component({
  selector: 'ns-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
})
export class GlobalSearchComponent implements OnInit {

  @Output()
    searchIconHiddenEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  searchFieldHidden = false;
  searchFieldControl = new FormControl();
  loadingIndicator = false;
  searchResults: Observable<SearchResult[]>;
  searchIconHidden = false;
  allSubscriptions = new Subscription(); // So that we can unsubscribe to all observable at once.
  searchedArtists: SearchResult[] = [];
  searchedAlbums: SearchResult[] = [];
  searchedNasheeds: SearchResult[] = [];
  searchedAuthors: SearchResult[] = [];
  constructor(
    public domService: DomService,
    private firebaseService: FirebaseService,
    private router: Router,
    public languageService: LanguageService,
    public themeService: ThemeService,
    public dialog: MatDialog) {
  }
  ngOnInit(): void {
    this.allSubscriptions.add(this.domService.isHandset$.subscribe((mobile) => {
      this.searchFieldHidden = mobile;
      this.searchIconHidden = !mobile;
      this.searchIconHiddenEmitter.emit(!mobile);
    }));

    this.searchResults = Utils.getSearchResults<SearchResult>({
      searchType: SearchType.All,
      formControl: this.searchFieldControl,
      firebaseService: this.firebaseService,
    });
    this.searchResults.subscribe(result => {
      this.searchedNasheeds = result.filter(val => val.isNasheed);
      this.searchedArtists = result.filter(val => val.isArtist);
      this.searchedAuthors = result.filter(val => val.isAuthor);
      this.searchedAlbums = result.filter(val => val.isAlbum);
    });
    this.searchFieldControl.valueChanges.subscribe((value: string | SearchResult) => {
      if (typeof value === 'string' && !value.trim()) {
        this.searchedNasheeds = [];
        this.searchedAlbums = [];
        this.searchedArtists = [];
        this.searchedAuthors = [];
      }
    });
  }
  eraseSearching(): void {
    this.searchFieldControl.setValue('');
    this.searchedNasheeds = [];
    this.searchedAlbums = [];
    this.searchedArtists = [];
    this.searchedAuthors = [];
  }
  onSelectionChanged(event: MatAutocompleteSelectedEvent): void {
    const selectedResult: SearchResult = event.option.value;
    const titleInCurrentLanguage = (selectedResult.title[this.languageService.getCurrentLanguage().iso] || selectedResult.title.en).trim();
    const targetTitle = `${titleInCurrentLanguage.replace(/\s+/g, '-').toLowerCase()}-${selectedResult.docId}`;
    this.searchFieldControl.setValue(titleInCurrentLanguage);

    if (selectedResult.isNasheed) {
      this.router.navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigateByUrl(`/details/nasheed/${targetTitle}`).catch(console.warn))
        .catch(console.warn);
    } else if (selectedResult.isArtist) {
      this.router.navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate([`/details/artist/${targetTitle}`]).catch(console.warn))
        .catch(console.warn);
    }  else if (selectedResult.isAlbum) {
      this.router.navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate([`/details/album/${targetTitle}`]).catch(console.warn)).catch(console.warn);
    } else if (selectedResult.isAuthor) {
      this.router.navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate([`/details/author/${targetTitle}`]).catch(console.warn))
        .catch(console.warn);
    }
  }
  hideSearchField(): void {
    this.searchIconHidden = false;
    this.searchFieldHidden = true;
    this.searchFieldControl.setValue('');
    this.searchIconHiddenEmitter.emit(false);
  }
  showSearchField(): void {
    if (this.searchFieldHidden) {
      this.searchIconHidden = true;
      this.searchIconHiddenEmitter.emit(true);
    }
    this.searchFieldHidden = false;
    setTimeout(() => document.getElementById('input').focus(), 100);
  }

}
