import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from '@angular-shared/components/breadcrumbs/breadcrumbs.component';
import { MatCardModule } from '@angular/material/card';
import { I18nModule } from '../../../i18n.module';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    I18nModule,
  ],
  exports: [
    BreadcrumbsComponent,
  ],
})
export class NsBreadcrumbsModule { }
