import {
  Component, OnInit, Input, ViewChild, Output, ElementRef, OnChanges, SimpleChanges, HostListener,
} from '@angular/core';
import { Track } from '@interfaces/track.model';
import { MatSliderChange } from '@angular/material/slider';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { PlayerService } from '@services/player.service';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language.service';

@Component({
  selector: 'mat-advanced-audio-player,audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.css'],
})
export class AudioPlayerComponent implements OnInit, OnChanges {

  @Input() displayTitle = true;
  @Input() displayPlaylist = true;
  @Input() displayVolumeControls = true;
  @Input() displayRepeatControls = true;
  @Input() pageSizeOptions = [10, 20, 30];
  @Input() expanded = true;
  @Input() autoPlay = false;
  @Input() disablePositionSlider = false;
  @Input() displayDuration = false;
  // Support for internationalization
  @Input() tableHeader = 'Playlist';
  @Input() titleHeader = 'Title';
  @Input() artistHeader = 'Artist';
  @Input() durationHeader = 'Duration';
  @Input()
  public endOffset = 0;
  @Output()
    trackEnded: Subject<string> = new Subject<string>();
  @ViewChild('audioPlayer', { static: true }) playerRef: ElementRef;
  repeat = 'all';
  displayedColumns: string[];
  dataSource = new MatTableDataSource<Track>();
  paginator: MatPaginator;
  tracks: Track[] = [];
  displayArtist = true;
  currentIndex = 0;
  player: HTMLAudioElement;
  iOS = (/iPad|iPhone|iPod/.test(navigator.platform)
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
  loaderDisplay = false;
  isPlaying = false;
  currentTime = 0;
  volume = 0.1;
  duration = 0.01;
  private startOffsetValue = 0;
  constructor( public languageService: LanguageService, public audioPlayerService: PlayerService) {
    this.audioPlayerService.playFunction = this.play;
  }
  get startOffset(): number {
    return this.startOffsetValue;
  }
  @Input()
  set startOffset(seconds: number) {
    this.startOffsetValue = seconds;
    this.player.currentTime = seconds;
  }
  @Input()
  set playlist(playlist: Track[]) {
    this.audioPlayerService.setPlaylist(playlist);
  }
  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    const eventTarget = event.target as HTMLElement;
    if (!this.checkIfNasheedHasStartedSinceAtLeastTwoSeconds()) {
      return;
    }
    if (!['INPUT', 'BUTTON', 'TEXTAREA'].includes(eventTarget.nodeName)) {
      if (event.code === 'Space') {
        event.preventDefault();
        if (this.audioPlayerService.audioPlayer.paused) {
          this.audioPlayerService.resume();
        } else {
          this.audioPlayerService.pause();
        }
      }
    }
  }
  ngOnInit(): void {
    this.player = this.playerRef.nativeElement as HTMLAudioElement;
    this.bindPlayerEvent();
    this.audioPlayerService.playerElement = this.playerRef;

    this.player.addEventListener('ended', () => {
      if (this.checkIfNasheedHasStartedSinceAtLeastTwoSeconds()) {
        if (this.repeat === 'all') {
          if (this.tracks.length > 1) {
            this.nextSong();
            return;
          }
          this.play();
        } else if (this.repeat === 'one') {
          this.play();
        } else if (this.repeat === 'none') {
          // Do nothing
        }
      }
    });

    this.player.addEventListener('timeupdate', () => {
      this.audioPlayerService.setCurrentTime(this.player.currentTime);
    });

    // Subscribe to playlist observer from AudioPlayerService and
    // update the playlist within MatAdvancedAudioPlayerComponent
    this.audioPlayerService.getPlaylist().subscribe((tracks) => {
      if (tracks?.length) {
        this.tracks = tracks;
        this.initialize();
      }
    });
  }
  initialize(): void {
    this.buildDisplayedColumns();

    // populate indexs for the track and configure
    // material table data source and paginator
    this.setDataSourceAttributes();

    this.player.currentTime = this.startOffset;
    this.updateCurrentTrack();

    if (this.autoPlay) {
      this.play();
    }
  }
  setDataSourceAttributes(): void {
    let index = 1;
    if (this.tracks) {
      this.tracks.forEach((track: Track) => {
        track.index = index++;
      });
      this.dataSource = new MatTableDataSource<Track>(this.tracks);
      this.dataSource.paginator = this.paginator;
    }
  }
  nextSong(): void {
    if (this.displayPlaylist === true
      && (((this.currentIndex + 1) % this.paginator.pageSize) === 0
        || (this.currentIndex + 1) === this.paginator.length)) {
      if (this.paginator.hasNextPage()) {
        this.paginator.nextPage();
      } else if (!this.paginator.hasNextPage()) {
        this.paginator.firstPage();
      }
    }
    this.currentTime = 0;
    this.duration = 0.01;
    if ((this.currentIndex + 1) >= this.tracks.length) {
      this.currentIndex = 0;
    } else {
      this.currentIndex++;
    }
    this.updateCurrentTrack();
    this.play();
  }
  previousSong(): void {
    this.currentTime = 0;
    this.duration = 0.01;
    if (!this.checkIfNasheedHasStartedSinceAtLeastTwoSeconds()) {
      if (this.displayPlaylist === true
        && (((this.currentIndex) % this.paginator.pageSize) === 0
          || (this.currentIndex === 0))) {
        if (this.paginator.hasPreviousPage()) {
          this.paginator.previousPage();
        } else if (!this.paginator.hasPreviousPage()) {
          this.paginator.lastPage();
        }
      }
      if ((this.currentIndex - 1) < 0) {
        this.currentIndex = (this.tracks.length - 1);
      } else {
        this.currentIndex--;
      }
    } else {
      this.resetSong();
    }
    this.updateCurrentTrack();
    this.play();
  }
  resetSong(): void {
    this.player.src = this.tracks[this.currentIndex].mp3;
  }
  selectTrack(index: number): void {
    this.currentIndex = index - 1;
    this.updateCurrentTrack();
    this.play();
  }
  checkIfNasheedHasStartedSinceAtLeastTwoSeconds(): boolean {
    return this.player.currentTime > 2;
  }
  updateCurrentTrack(): void {
    this.audioPlayerService.setCurrentTrack(this.tracks[this.currentIndex]);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('displayArtist') || changes.hasOwnProperty('displayDuration')) {
      this.buildDisplayedColumns();
    }
  }
  currTimePosChanged(event: MatSliderChange): void {
    this.player.currentTime = event.value;
  }
  bindPlayerEvent(): void {
    this.player.addEventListener('playing', () => {
      this.isPlaying = true;
      this.duration = Math.floor(this.player.duration);
      this.audioPlayerService.currentTrackDuration = this.duration;
    });
    this.player.addEventListener('pause', () => {
      this.isPlaying = false;
    });
    this.player.addEventListener('timeupdate', () => {
      this.currentTime = Math.floor(this.player.currentTime);
      // BUG: Commenting for `ended` event not firing #66
      // if (this.currentTime >= this.duration - this.endOffset) {
      //     this.player.nativeElement.pause();
      // }
    });
    this.player.addEventListener('volume', () => {
      this.volume = Math.floor(this.player.volume);
    });
    if (!this.iOS) {
      this.player.addEventListener('loadstart', () => {
        this.loaderDisplay = true;
      });
    }
    this.player.addEventListener('loadedmetadata', () => {
      this.loaderDisplay = false;
      this.duration = Math.floor(this.player.duration);
    });
    this.player.addEventListener('ended', () => {
      this.trackEnded.next('ended');
    });
  }
  playBtnHandler(): void {
    if (this.loaderDisplay) {
      return;
    }
    if (this.player.paused) {
      if (this.currentTime >= this.duration - this.endOffset) {
        this.player.currentTime = this.startOffset;
      } else {
        this.player.currentTime = this.currentTime;
      }

      this.player.play().catch(console.warn);
    } else {
      this.currentTime = this.player.currentTime;
      this.player.pause();
    }
  }
  play(track?: Track): void {
    if (track) {
      this.startOffset = track.startOffset || 0;
      this.endOffset = track.endOffset || 0;
    }

    setTimeout(() => {
      this.player.play().catch(console.warn);
    }, 50);
  }
  toggleVolume(): void {
    if (this.volume === 0) {
      this.setVolume(1.0);
    } else {
      this.setVolume(0);
    }
  }
  toggleRepeat(): void {
    if (this.repeat === 'none') {
      this.repeat = 'all';
    } else if (this.repeat === 'all') {
      if (this.tracks.length > 1) {
        this.repeat = 'one';
      } else {
        this.repeat = 'none';
      }
    } else if (this.repeat === 'one' && this.tracks.length > 1) {
      this.repeat = 'none';
    }
  }
  /*toggleRepeatOne() {
    if (this.repeat === 'none') {
      this.repeat = 'one';
    } else if (this.repeat === 'all') {
      if (this.tracks.length > 1) {
        this.repeat = 'one';
      } else {
        this.repeat = '';
      }
    } else if (this.repeat === 'one' && this.tracks.length > 1) {
      this.repeat = 'none';
    }
  }*/
  private setVolume(vol): void {
    this.volume = vol;
    this.player.volume = this.volume;
  }
  private buildDisplayedColumns(): void {
    this.displayedColumns = ['title'];
    if (this.displayArtist) {
      this.displayedColumns.push('artist');
    }
    if (this.displayDuration) {
      this.displayedColumns.push('duration');
    }
    this.displayedColumns.push('status');
  }

}
