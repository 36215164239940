export enum ValidRoutes {
  Root = '/',
  UploadNasheed = 'upload-nasheed',
  Login = 'login',
  Artists = 'artists',
  Details = 'details', // Todo: Remember if you change this, you have to also update /functions/src/server-router.ts -> path.includes conditions
  Moderate = 'moderate',
  PrivacyPolicy = 'privacy-policy',
  DeleteAccount = 'delete-account',
  ContactAndSupport = 'support',
}
