@if ({
  currentLanguage: languageService.currentLanguage$ | async,
  isMobile: domService.isHandset$ | async,
  darkThemeEnabled: themeService.darkThemeEnabled | async
}; as AsyncItems) {
  @if (!searchFieldHidden) {
    <div class="search-bar-container">
      <div [dir]="AsyncItems.currentLanguage.direction"
           class="searchBox"
      >
        <mat-icon>search</mat-icon>
        <input [formControl]="searchFieldControl"
               [matAutocomplete]="auto"
               [style.padding]="AsyncItems.isMobile ? '5px' : '6px'"
               class="adjust-bw-color"
               id="input"
               matInput
               placeholder="{{'common.search'|translate}}"
               type="text"/>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)"
                          [class]="AsyncItems.isMobile ? (AsyncItems.currentLanguage.direction === 'ltr' ? 'auto-complete-container-mobile-ltr' : 'auto-complete-container-mobile-rtl'): null"
                          [dir]="AsyncItems.currentLanguage.direction"
                          class="auto-complete-container">
          @if (loadingIndicator) {
            <mat-option>
              <mat-progress-bar [color]="AsyncItems.darkThemeEnabled ? 'warn' : 'primary'"
                                mode="indeterminate"></mat-progress-bar>
            </mat-option>
          }

          @if (searchedNasheeds.length) {
            <span class="heading-text">{{ 'nasheed.singular' | translate | uppercase }}
              @if (AsyncItems.currentLanguage.direction === 'ltr') {
                <span>(S)</span>
              }
            </span>
          }

          @for (searchResult of searchedNasheeds; track searchResult) {
            <mat-option [value]="searchResult">
              <ns-list-item [category]="'nasheed'"
                            [searchResult]="{title: searchResult.title[languageService.getCurrentLanguage().iso], subTitle: searchResult?.artistDetail?.title[languageService.getCurrentLanguage().iso], thumbnail: searchResult.thumbnail}"
                            [searchedText]="searchFieldControl.value"
                            [showProfileFallBackImage]="false"
              ></ns-list-item>
            </mat-option>
          }

          @if (searchedAlbums.length) {
            <span class="heading-text">{{ 'album.singular' | translate | uppercase }}
              @if (AsyncItems.currentLanguage.direction === 'ltr') {
                <span>(S)</span>
              }
            </span>
          }

          @for (searchResult of searchedAlbums; track searchResult) {
            <mat-option [value]="searchResult">
              <ns-list-item [category]="'album'"
                            [searchResult]="{title: searchResult.title[languageService.getCurrentLanguage().iso], subTitle: searchResult?.artistDetail?.title[languageService.getCurrentLanguage().iso], thumbnail: searchResult.thumbnail}"
                            [searchedText]="searchFieldControl.value"
                            [showProfileFallBackImage]="false"
              ></ns-list-item>
            </mat-option>
          }

          @if (searchedArtists.length) {
            <span class="heading-text">{{ 'artist.singular' | translate | uppercase }}
              @if (AsyncItems.currentLanguage.direction === 'ltr') {
                <span>(S)</span>
              }
            </span>
          }

          @for (searchResult of searchedArtists; track searchResult) {
            <mat-option [value]="searchResult">
              <ns-list-item [category]="'artist'"
                            [searchResult]="{title: searchResult.title[languageService.getCurrentLanguage().iso], subTitle: searchResult?.artistDetail?.title[languageService.getCurrentLanguage().iso], thumbnail: searchResult.thumbnail}"
                            [searchedText]="searchFieldControl.value"
                            [showProfileFallBackImage]="true"
              ></ns-list-item>
            </mat-option>
          }

          @if (searchedAuthors.length) {
            <span class="heading-text">{{ 'author.singular' | translate | uppercase }}
              @if (AsyncItems.currentLanguage.direction === 'ltr') {
                <span>(S)</span>
              }
            </span>
          }

          @for (searchResult of searchedAuthors; track searchResult) {
            <mat-option [value]="searchResult">
              <ns-list-item [category]="'author'"
                            [searchResult]="{title: searchResult.title[languageService.getCurrentLanguage().iso], subTitle: searchResult?.artistDetail?.title[languageService.getCurrentLanguage().iso], thumbnail: searchResult.thumbnail}"
                            [searchedText]="searchFieldControl.value"
                            [showProfileFallBackImage]="true"
              ></ns-list-item>
            </mat-option>
          }

          <div class="mt-10"></div>
        </mat-autocomplete>
        @if (AsyncItems.isMobile) {
          <button (click)="searchFieldControl.value?.trim() ? eraseSearching(): hideSearchField()"
                  class="clear-search-button"
                  mat-icon-button
                  type="submit"
          >
            <mat-icon>close</mat-icon>
          </button>
        }
        @if (!AsyncItems.isMobile && searchFieldControl.value?.trim()) {
          <button (click)="eraseSearching()"
                  class="clear-search-button"
                  mat-icon-button
                  type="submit"
          >
            <mat-icon>close</mat-icon>
          </button>
        }
      </div>
    </div>
  }

  @if (!searchIconHidden) {
    <button (click)="showSearchField()" mat-icon-button style="float: right"
            type="submit">
      <mat-icon>search</mat-icon>
    </button>
  }
}
